// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-checkout-page-js": () => import("./../../../src/templates/checkoutPage.js" /* webpackChunkName: "component---src-templates-checkout-page-js" */),
  "component---src-templates-cms-page-js": () => import("./../../../src/templates/cmsPage.js" /* webpackChunkName: "component---src-templates-cms-page-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/collectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-cookie-policy-page-js": () => import("./../../../src/templates/cookiePolicyPage.js" /* webpackChunkName: "component---src-templates-cookie-policy-page-js" */),
  "component---src-templates-customer-service-page-js": () => import("./../../../src/templates/customerServicePage.js" /* webpackChunkName: "component---src-templates-customer-service-page-js" */),
  "component---src-templates-error-out-of-stock-page-js": () => import("./../../../src/templates/errorOutOfStockPage.js" /* webpackChunkName: "component---src-templates-error-out-of-stock-page-js" */),
  "component---src-templates-error-page-js": () => import("./../../../src/templates/errorPage.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faqPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-router-js": () => import("./../../../src/templates/router.js" /* webpackChunkName: "component---src-templates-router-js" */),
  "component---src-templates-search-result-page-js": () => import("./../../../src/templates/searchResultPage.js" /* webpackChunkName: "component---src-templates-search-result-page-js" */),
  "component---src-templates-success-klarna-page-js": () => import("./../../../src/templates/successKlarnaPage.js" /* webpackChunkName: "component---src-templates-success-klarna-page-js" */),
  "component---src-templates-success-page-js": () => import("./../../../src/templates/successPage.js" /* webpackChunkName: "component---src-templates-success-page-js" */)
}

