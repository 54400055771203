import React from "react"
import { I18nextProvider } from "react-i18next"
import { i18nextConfig } from "./src/i18n/locales/config"
import { CookiesProvider } from "react-cookie"
import { BrinkContextProvider } from "./src/components/context/BrinkContext"
import "lazysizes"
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  const { sanityTranslations } = props.pageContext
  return (
    <I18nextProvider i18n={i18nextConfig(sanityTranslations)}>
      <CookiesProvider>
        <BrinkContextProvider>{element}</BrinkContextProvider>
      </CookiesProvider>
    </I18nextProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps }) =>
  routerProps.location.state && routerProps.location.state.disableScrollUpdate
    ? false
    : true

// GTAG disabled default in gatsby gtag plugin.
if (process.env.NODE_ENV === "development") window.gtag = () => {}
