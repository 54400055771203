import Dinero from "dinero.js"
import axios from "axios"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("klaviyo")

export const addedToCart = ({
  cartResponse,
  productVariantId,
  quantity,
  currentStore
}) => {
  const _learnq = dispatcher(window._learnq)
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  if (addedProduct.type !== "productVariant") return

  const klavioCart = {
    $value: toDinero(
      cartResponse.totalPriceWithDiscount,
      currentStore.currencyUnit
    ).toUnit(),
    AddedItemProductName: addedProduct.name,
    AddedItemProductID: addedProduct.id,
    AddedItemImageURL: addedProduct.imageUrl,
    AddedItemPrice: toDinero(
      addedProduct.price[currentStore.currencyUnit],
      currentStore.currencyUnit
    ).toUnit(),
    AddedItemQuantity: quantity,
    CountryCode: currentStore.countryCode,
    ItemNames: cartResponse.cartItems.map((ct) => ct.name),
    CheckoutURL: `${window.location.origin}/checkout`,
    Items: toKlaviyoItems(cartResponse.cartItems, currentStore.currencyUnit)
  }
  const resp = _learnq.push(["track", "Added to Cart", klavioCart])
  console.debug("Set klaviyo added to cart response: ", resp)
}

export const identify = ({ address }) => {
  const _learnq = dispatcher(window._learnq)
  const resp = _learnq.push([
    "identify",
    {
      $email: address.email,
      $first_name: address.firstName,
      $last_name: address.lastName
    }
  ])
  console.debug("Set klaviyo identity response: ", resp)
}

export const startedCheckout = ({
  storedCart,
  currentStore,
  brinkSessionId
}) => {
  const _learnq = dispatcher(window._learnq)
  const cartProuductVariants = storedCart.cartItems.filter(
    (i) => i.type === "productVariant"
  )
  const resp = _learnq.push([
    "track",
    "Started Checkout",
    {
      $event_id: `${storedCart.id}_${new Date().valueOf()}`,
      $value: toDinero(
        storedCart.totalPriceWithDiscount,
        currentStore.currencyUnit
      ).toUnit(),
      CountryCode: currentStore.countryCode,
      ItemNames: cartProuductVariants.map((ct) => ct.name),
      CheckoutURL: `${window.location.origin}/checkout/?cart=${brinkSessionId}`,
      Items: toKlaviyoItems(cartProuductVariants, currentStore.currencyUnit)
    }
  ])
  console.debug("Set klaviyo started checkout response: ", resp)
}

export const placedOrder = ({
  order,
  billingAddress: _billingAddress,
  klarnaOrder,
  discountCode,
  currentStore,
  cart
}) => {
  const billingAddress = klarnaOrder
    ? toBillingAddress(klarnaOrder)
    : _billingAddress
  const _learnq = dispatcher(window._learnq)
  const currencyUnit = order
    ? order.currencyUnit
    : klarnaOrder.purchase_currency
  const orderId = order ? order.id : klarnaOrder.merchant_reference2
  const event = [
    "track",
    "Placed Order",
    {
      customer_properties: {
        $email: billingAddress.email,
        $first_name: billingAddress.firstName,
        $last_name: billingAddress.lastName,
        $phone_number: billingAddress.phone,
        $address1: billingAddress.address,
        $address2: billingAddress.houseNumberOrName || "",
        $city: billingAddress.city,
        $zip: billingAddress.postalCode,
        $region: "",
        $country: billingAddress.country
      },
      properties: {
        $event_id: orderId,
        $value: toDinero(
          order ? order.orderAmountWithDiscount : klarnaOrder.order_amount,
          currencyUnit
        ).toUnit(),
        CountryCode: currentStore.countryCode,
        DiscountCode: discountCode || "",
        DiscountValue: toDiscountValue(order, klarnaOrder, currencyUnit),
        ItemNames: cart.cartItems.map((ct) => ct.name),
        Items: toKlaviyoItems(cart.cartItems, currencyUnit)
      },
      BillingAddress: toKlavioAddress(billingAddress),
      ShippingAddress: toKlavioAddress(billingAddress)
    }
  ]
  const resp = _learnq.push(event)
  console.debug("Set klaviyo placedOrder response: ", resp)
  orderedProducts(
    billingAddress,
    orderId,
    cart.cartItems,
    currentStore.currencyUnit
  )
}

const toBillingAddress = (billingAddress) => ({
  email: billingAddress.email,
  firstName: billingAddress.given_name,
  lastName: billingAddress.family_name,
  phone: billingAddress.phone,
  address: billingAddress.street_address,
  houseNumberOrName: billingAddress.street_address2,
  city: billingAddress.city,
  postalCode: billingAddress.postal_code,
  region: "",
  country: billingAddress.country
})

const toDiscountValue = (order, klarnaOrder, currencyUnit) => {
  if (order) {
    return toDinero(order.orderAmount, currencyUnit)
      .subtract(toDinero(order.orderAmountWithDiscount, currencyUnit))
      .toUnit()
  } else {
    return toDinero(
      klarnaOrder.order_lines
        .filter((o) => o.type === "physical")
        .reduce(
          (result, current) => (result += current.total_discount_amount),
          0
        ),
      currencyUnit
    ).toUnit()
  }
}

const orderedProducts = (billingAddress, orderId, cartItems, currencyUnit) => {
  const _learnq = dispatcher(window._learnq)
  const orderedProductsEvent = [
    "track",
    "Ordered Products",
    {
      customer_properties: {
        $email: billingAddress.email,
        $first_name: billingAddress.firstName,
        $last_name: billingAddress.lastName,
        $country: billingAddress.country
      },
      properties: {
        OrderId: orderId,
        Items: toKlaviyoItems(cartItems, currencyUnit)
      }
    }
  ]

  const resp = _learnq.push(orderedProductsEvent)
  console.debug("Set klaviyo orderedProducts response: ", resp)
}

export const viewProduct = ({ sanityProductVariant, languageCode }) => {
  if (!sanityProductVariant) return null
  const _learnq = dispatcher(window._learnq)
  const resp = _learnq.push([
    "track",
    "Viewed Product",
    {
      ProductName: sanityProductVariant.title,
      ProductID: sanityProductVariant._id,
      LanguageCode: languageCode
    }
  ])
  console.debug("Set klaviyo Viewed Product response: ", resp)
}

const toKlavioAddress = (address) => {
  return {
    email: address.email,
    first_name: address.firstName,
    last_name: address.lastName,
    phone_number: address.phone,
    address1: address.address,
    address2: address.houseNumberOrName || "",
    city: address.city,
    zip: address.postalCode,
    region: "",
    country: address.country
  }
}

const toKlaviyoItems = (items, currencyUnit) => {
  return items.map((item) => ({
    ProductID: item.id,
    ProductName: item.name,
    Quantity: item.quantity,
    ItemPrice: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
    RowTotal: toDinero(item.price[currencyUnit], currencyUnit)
      .multiply(item.quantity)
      .toUnit(),
    ImageURL: item.imageUrl,
    ProductURL: `${window.location.origin}/product/${item.slug}`
  }))
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export const getKlavyioApiKey = (countryCode) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyApiKeys = {
      test: {
        default: "Rpii3c"
      },
      production: {
        default: "XEFSRE"
      }
    }
    const key = klavioyApiKeys[env][countryCode] || klavioyApiKeys[env].default
    if (!key) console.error("Missing Klaviyo Apikey")
    return key
  } catch (error) {
    console.error("Missing Klaviyo Apikey")
    console.error(error)
  }
}

export const getKlavyioListIds = (countryCode) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyListIds = {
      test: {
        default: "TXbeMh"
      },
      production: {
        default: "SX3x9H"
      }
    }
    const key = klavioyListIds[env][countryCode] || klavioyListIds[env].default
    if (!key) console.error("Missing Klaviyo List ID")
    return key
  } catch (error) {
    console.error("Missing Klaviyo List ID")
    console.error(error)
  }
}

export const subscribeToNewsletter = async (countryCode, email) => {
  const config = Object.assign(
    {},
    {
      g: getKlavyioListIds(countryCode),
      email: email
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    })
  ).data
}
