import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"
import axios from "axios"
import Cookies from "js-cookie"
import { sha256 } from "js-sha256"

const dispatcher = createDispatcher("facebookPixel")

const pixelConversionApi = (eventName, eventId, data) => {
  if (process.env.NODE_ENV === "production" && process.env.GATSBY_PIXEL_ID) {
    const fbc = Cookies.get("_fbc")
    const fbp = Cookies.get("_fbp")
    axios
      .post(
        `${process.env.GATSBY_BRINKCOMMERCE_API_URL}/integrationv1/integrations/facebook/graph/events`,
        {
          custom_data: data,
          event_name: eventName,
          event_id: eventId,
          event_source_url: window.location.href,
          ...(fbc && { fbc: fbc }),
          ...(fbp && { fbp: fbp })
        }
      )
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const generateEventId = () =>
  `${Date.now()}.${Math.floor(100000 + Math.random() * 900000)}`

const getNumItems = (cartItems) =>
  cartItems
    .filter((p) => p.type === "productVariant")
    .reduce((a, b) => a + b.quantity, 0)

const viewContent = ({
  sanityProduct,
  sanityProductVariant,
  prices,
  currentStore
}) => {
  const fbq = dispatcher(window.fbq)
  const eventId = generateEventId()
  let data
  if (sanityProductVariant && prices) {
    data = {
      content_name: sanityProductVariant.title,
      content_ids: [sanityProductVariant._id],
      content_type: "product",
      value: Dinero(
        prices.find((p) => p.currencyUnit === currentStore.currencyUnit)
      ).toUnit(),
      currency: currentStore.currencyUnit
    }
  } else if (sanityProduct) {
    data = {
      content_name: sanityProduct.title,
      content_ids: [sanityProduct._id],
      content_type: "product"
    }
  } else {
    return null
  }
  fbq("track", "ViewContent", data, { eventID: eventId })
  pixelConversionApi("ViewContent", eventId, data)
}

const addToCart = ({ cartResponse, productVariantId, currentStore }) => {
  const fbq = dispatcher(window.fbq)
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )

  const eventId = generateEventId()
  const data = {
    content_name: addedProduct.name,
    content_ids: [addedProduct.id],
    content_type: "product",
    value: Dinero({
      amount: cartResponse.totalPriceWithDiscount,
      currency: currentStore.currencyUnit
    }).toUnit(),
    num_items: getNumItems(cartResponse.cartItems),
    currency: currentStore.currencyUnit
  }

  fbq("track", "AddToCart", data, { eventID: eventId })
  pixelConversionApi("AddToCart", eventId, data)
}

const initiateCheckout = ({ storedCart, currentStore }) => {
  const fbq = dispatcher(window.fbq)

  const eventId = generateEventId()
  const data = {
    content_ids: storedCart.cartItems.map((ct) => ct.id),
    contents: storedCart.cartItems.map((ct) => ({
      id: ct.id,
      quantity: ct.quantity
    })),
    content_type: "product",
    currency: currentStore.currencyUnit,
    num_items: getNumItems(storedCart.cartItems),
    value: Dinero({
      amount: storedCart.totalPriceWithDiscount,
      currency: currentStore.currencyUnit
    }).toUnit()
  }
  fbq("track", "InitiateCheckout", data, { eventID: eventId })
  pixelConversionApi("InitiateCheckout", eventId, data)
}

const hashAndTrim = (obj) =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] =
      typeof obj[curr] === "string" ? sha256(obj[curr].trim()) : obj[curr]
    return acc
  }, {})

const purchase = ({
  billingAddress: _billingAddress,
  klarnaOrder,
  cart,
  currentStore
}) => {
  const billingAddress = klarnaOrder
    ? toBillingAddress(klarnaOrder.billing_address)
    : _billingAddress

  const fbq = dispatcher(window.fbq)
  const eventId = generateEventId()
  const customer_properties = hashAndTrim({
    email: billingAddress.email,
    first_name: billingAddress.firstName,
    last_name: billingAddress.lastName,
    phone_number: billingAddress.phone,
    address1: billingAddress.address,
    address2: billingAddress.houseNumberOrName || "",
    city: billingAddress.city,
    zip: billingAddress.postalCode,
    region: "",
    country: billingAddress.country
  })

  const data = {
    content_ids: cart.cartItems.map((ct) => ct.id),
    contents: cart.cartItems.map((ct) => ({
      id: ct.id,
      quantity: ct.quantity
    })),
    content_type: "product",
    currency: currentStore.currencyUnit,
    num_items: cart.cartItems.reduce((a, b) => (a += b.quantity), 0),
    value: Dinero({
      amount: cart.totalPriceWithDiscount,
      currency: currentStore.currencyUnit
    }).toUnit(),
    customer_properties
  }
  fbq("track", "Purchase", data, { eventID: eventId })
  pixelConversionApi("Purchase", eventId, data)
}

const toBillingAddress = (billingAddress) => ({
  email: billingAddress.email,
  firstName: billingAddress.given_name,
  lastName: billingAddress.family_name,
  phone: billingAddress.phone,
  address: billingAddress.street_address,
  houseNumberOrName: billingAddress.street_address2,
  city: billingAddress.city,
  postalCode: billingAddress.postal_code,
  region: "",
  country: billingAddress.country
})

export { purchase, initiateCheckout, addToCart, viewContent }
