import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("pinterestTag")

export const addToCart = ({
  cartResponse,
  productVariantId,
  quantity,
  currentStore
}) => {
  const pintrk = dispatcher(window.pintrk)
  const { currencyUnit } = currentStore
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  pintrk("track", "addtocart", {
    product_name: addedProduct.name,
    product_ids: [addedProduct.id],
    order_quantity: quantity,
    currency: currencyUnit,
    value: Dinero({
      amount: addedProduct.price[currencyUnit],
      currency: currencyUnit
    }).toUnit()
  })
}

export const purchase = ({ cart, currentStore }) => {
  const pintrk = dispatcher(window.pintrk)
  const { currencyUnit } = currentStore
  pintrk("track", "checkout", {
    value: Dinero({
      amount: cart.totalPriceWithDiscount,
      currency: currencyUnit
    }).toUnit(),
    order_quantity: 1,
    currency: currencyUnit,
    line_items: cart.cartItems.map((item) => ({
      product_name: item.name,
      product_id: item.id,
      product_price: Dinero({
        amount: item.price[currencyUnit],
        currency: currencyUnit
      }),
      product_quantity: item.quantity
    }))
  })
}

export const subscribeToNewsletter = () => {
  const pintrk = dispatcher(window.pintrk)
  pintrk("track", "lead", { lead_type: "Newsletter" })
}
