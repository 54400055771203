import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("google")

export const addToCart = ({
  productVariantId,
  name,
  quantity,
  category,
  listName
}) => {
  const gtag = dispatcher(window.gtag)
  gtag("event", "add_to_cart", {
    items: [
      {
        id: productVariantId,
        brand: "Rondure",
        name: name,
        quantity: quantity,
        category: category,
        list_name: listName
      }
    ]
  })
}

export const removeFromCart = ({
  productVariantId,
  name,
  quantity,
  category,
  listName
}) => {
  const gtag = dispatcher(window.gtag)
  gtag("event", "remove_from_cart", {
    items: [
      {
        id: productVariantId,
        brand: "Rondure",
        name: name,
        quantity: quantity,
        category: category,
        list_name: listName
      }
    ]
  })
}

const createViewItem = (product, category, listName) => ({
  id: product._id,
  name: product.title,
  brand: "Rondure",
  category: category,
  list_name: listName
})

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  listName
}) => {
  const gtag = dispatcher(window.gtag)
  const category = sanityProduct.category
    ? sanityProduct.category.displayName.en
    : ""
  gtag("event", sanityProductVariant ? "view_item" : "view_item_list", {
    items: sanityProductVariant
      ? [createViewItem(sanityProductVariant, category, listName)]
      : sanityProduct.variants.map((p) => createViewItem(p, category, listName))
  })
}

export const viewCollection = ({ products, listName }) => {
  const gtag = dispatcher(window.gtag)
  gtag("event", "view_item_list", {
    items: products.map((p) =>
      createViewItem(p, p.category ? p.category.displayName.en : "", listName)
    )
  })
}

export const purchase = ({
  order,
  klarnaOrder,
  cart,
  listName,
  discountCode
}) => {
  const gtag = dispatcher(window.gtag)
  const purchaseEvent = toPurchaseEvent(order, klarnaOrder, cart, listName)
  gtag(
    "event",
    "purchase",
    discountCode ? { ...purchaseEvent, coupon: discountCode } : purchaseEvent
  )
}

const toPurchaseEvent = (order, klarnaOrder, cart, listName) => {
  const physicalOrderlines = order
    ? order.orderLines.filter((o) => o.type === "physical")
    : klarnaOrder.order_lines.filter((o) => o.type === "physical")
  if (order) {
    return {
      transaction_id: order.id,
      value: Dinero({
        amount: order.orderAmountWithDiscount,
        currency: order.currencyUnit
      }).toUnit(),
      currency: order.currencyUnit,
      tax: Dinero({
        amount: order.orderTaxAmount,
        currency: order.currencyUnit
      }).toUnit(),
      shipping: Dinero({
        amount: order.orderLines
          .filter((ol) => ol.type === "shipment")
          .reduce((result, current) => (result += current.totalAmount), 0),
        currency: order.currencyUnit
      }).toUnit(),
      items: physicalOrderlines.map((ol) => ({
        id: ol.productId,
        name: ol.name,
        brand: "Rondure",
        quantity: ol.quantity,
        category: cart.cartItems.find((c) => c.id === ol.productId).category,
        list_name:
          listName ||
          cart.cartItems.find((c) => c.id === ol.productId).category,
        price: Dinero({
          amount: ol.totalAmountWithDiscount,
          currency: order.currencyUnit
        }).toUnit()
      }))
    }
  } else {
    return {
      transaction_id: klarnaOrder.merchant_reference2,
      value: Dinero({
        amount: klarnaOrder.order_amount,
        currency: klarnaOrder.purchase_currency
      }).toUnit(),
      currency: klarnaOrder.purchase_currency,
      tax: Dinero({
        amount: klarnaOrder.order_tax_amount,
        currency: klarnaOrder.purchase_currency
      }).toUnit(),
      shipping: Dinero({
        amount: klarnaOrder.order_lines
          .filter((ol) => ol.type === "shipping_fee")
          .reduce((result, current) => (result += current.total_amount), 0),
        currency: klarnaOrder.purchase_currency
      }).toUnit(),
      items: physicalOrderlines.map((ol) => ({
        id: ol.reference,
        name: ol.name,
        brand: "Rondure",
        quantity: ol.quantity,
        category: cart.cartItems.find((c) => c.id === ol.reference).category,
        list_name:
          listName ||
          cart.cartItems.find((c) => c.id === ol.reference).category,
        price: Dinero({
          amount: ol.total_amount - ol.total_discount_amount,
          currency: klarnaOrder.purchase_currency
        }).toUnit()
      }))
    }
  }
}

export const initiateCheckout = ({ storedCart, listName }) => {
  const gtag = dispatcher(window.gtag)
  gtag("event", "checkout_progress", {
    checkout_step: 1,
    checkout_option: "Started Checkout"
  })
  gtag("event", "begin_checkout", {
    items: storedCart.cartItems.map((i) => ({
      id: i.id,
      name: i.name,
      brand: "Rondure",
      category: i.category,
      quantity: i.quantity,
      list_name: listName
    }))
  })
}

export const clickProduct = ({ product, listName, positionInList }) => {
  const gtag = dispatcher(window.gtag)
  gtag("event", "select_content", {
    content_type: "product",
    items: [
      {
        id: product._id || product.id,
        name: product.title,
        brand: "Rondure",
        category: product.category ? product.category.displayName.en : "",
        list_name: listName,
        list_position: positionInList
      }
    ]
  })
}
