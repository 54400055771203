import * as facebookPixel from "./facebookPixel"
import * as google from "./google"
import * as klaviyo from "./klaviyo"
import * as pinterestTag from "./pinterestTag"

const dispatchEvent = (props) => (event) => {
  try {
    event(props)
  } catch (error) {
    console.error("events:", error)
  }
}

const addToCartEvents = [
  facebookPixel.addToCart,
  google.addToCart,
  klaviyo.addedToCart,
  pinterestTag.addToCart
]

export const addToCart = (
  cartResponse,
  productVariantId,
  quantity,
  currentStore,
  category,
  listName,
  name
) =>
  addToCartEvents.forEach(
    dispatchEvent({
      cartResponse,
      productVariantId,
      name,
      quantity,
      currentStore,
      category,
      listName
    })
  )

const removeFromCartEvents = [google.removeFromCart]

export const removeFromCart = (
  productVariantId,
  name,
  quantity,
  category,
  listName
) =>
  removeFromCartEvents.forEach(
    dispatchEvent({
      productVariantId,
      name,
      quantity,
      category,
      listName
    })
  )

const purchaseEvents = [
  facebookPixel.purchase,
  google.purchase,
  klaviyo.placedOrder,
  pinterestTag.purchase
]

export const purchase = ({
  billingAddress,
  order,
  klarnaOrder,
  discountCode,
  cart,
  currentStore,
  listName
}) =>
  purchaseEvents.forEach(
    dispatchEvent({
      billingAddress,
      order,
      klarnaOrder,
      discountCode,
      cart,
      currentStore,
      listName
    })
  )

const initiateCheckoutEvents = [
  google.initiateCheckout,
  facebookPixel.initiateCheckout,
  klaviyo.startedCheckout
]

export const initiateCheckout = (storedCart, currentStore, brinkSessionId, listName) =>
  initiateCheckoutEvents.forEach(
    dispatchEvent({
      storedCart,
      currentStore,
      brinkSessionId,
      listName
    })
  )

const viewProductEvents = [
  facebookPixel.viewContent,
  google.viewProduct,
  klaviyo.viewProduct
]

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  languageCode,
  prices,
  currentStore,
  listName
}) =>
  viewProductEvents.forEach(
    dispatchEvent({
      sanityProductVariant,
      sanityProduct,
      languageCode,
      prices,
      currentStore,
      listName
    })
  )

const viewCollectionsEvents = [google.viewCollection]

export const viewCollection = (products, listName) =>
  viewCollectionsEvents.forEach(
    dispatchEvent({
      products,
      listName
    })
  )

const clickProductEvents = [google.clickProduct]

export const clickProduct = ({ product, listName, positionInList }) =>
  clickProductEvents.forEach(
    dispatchEvent({
      product,
      listName,
      positionInList
    })
  )

const identifyEvents = [klaviyo.identify]

export const identify = (address) =>
  identifyEvents.forEach(
    dispatchEvent({
      address
    })
  )
